//  == media mixin
mw($media = 1700px, $above = false)
	if($above)
		@media (min-width $media)
			{block}
	else
		@media (max-width $media)
			{block}
			
mh($media = 600px, $above = false)
	if($above)
		@media (min-height $media)
			{block}
	else
		@media (max-height $media)
			{block}

// == other
btn($color= #333, $hover= #fff)
	display block
	width 436px
	height 71px
	margin 0 auto  30px
	border 1px solid #000000
	display flex
	align-items center
	justify-content center
	font-size 20px
	font-weight 300
	color $color
	line-height 1
	text-decoration none
	background none
	outline none
	transition .3s
	user-select none
	&:hover
		background $color
		color $hover
		border-color $color
	.icons 
		position relative
		display flex
		align-items center
		justify-content flex-end
		min-width 2.2em
		

bg()
	background-size cover
	background-position center center
	background-repeat no-repeat
	

customScroll($w = 4px, $bgt = $color2, $b = 0px, $bg = $color )
	overflow auto
	@media (min-width:1241px)
		&::-webkit-scrollbar
			width $w

		&::-webkit-scrollbar-track
			background $bgt
			border $b
			
		&::-webkit-scrollbar-thumb
			background $bg
			
		-ms-overflow-style -ms-autohiding-scrollbar	