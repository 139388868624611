@font-face {
  font-family: 'GothamPro';
  src: url("../fonts/GothamPro/GothamPro.woff2") format('woff2'), url("../fonts/GothamPro/GothamPro.woff") format('woff'), url("../fonts/GothamPro/GothamPro.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'GothamPro';
  src: url("../fonts/GothamPro-Bold/GothamPro-Bold.woff2") format('woff2'), url("../fonts/GothamPro-Bold/GothamPro-Bold.woff") format('woff'), url("../fonts/GothamPro-Bold/GothamPro-Bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'GothamPro';
  src: url("../fonts/GothamPro-Light/GothamPro-Light.woff2") format('woff2'), url("../fonts/GothamPro-Light/GothamPro-Light.woff") format('woff'), url("../fonts/GothamPro-Light/GothamPro-Light.ttf") format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'GothamPro';
  src: url("../fonts/GothamPro-Medium/GothamPro-Medium.woff2") format('woff2'), url("../fonts/GothamPro-Medium/GothamPro-Medium.woff") format('woff'), url("../fonts/GothamPro-Medium/GothamPro-Medium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
}
body {
  font-family: 'GothamPro', sans-serif;
}
.job-container {
  position: relative;
  padding: 0 60px;
}
.job-header {
  margin-bottom: 74px;
}
.job-header .close_btn {
  top: 20px;
  right: 25px;
}
.job-header .close_btn:before,
.job-header .close_btn:after {
  margin: -1px 0 0 -16px;
}
.job-header__title {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin: 0;
  transform: translateX(-50%);
  font-size: 40px;
  font-weight: 300;
  line-height: 0.9;
}
.job-header .logo {
  display: block;
  width: 184px;
  height: 81px;
  margin-top: 71px;
}
@media (max-width: 1000px) {
  .job-header .close_btn {
    top: 0;
    right: 0;
  }
}
@media (max-width: 770px) {
  .job-header {
    margin-bottom: 30px;
  }
  .job-header .job-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .job-header .logo {
    width: 125px;
    height: 55px;
    margin: 34px 0 16px;
  }
  .job-header__title {
    position: static;
    transform: none;
  }
}
.job-gallery {
  position: relative;
  margin-bottom: 8px;
  background: #b89fba;
}
.job-gallery img {
  width: 100%;
}
.job-gallery__title {
  font-size: 36px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.job-gallery__cover {
  position: relative;
  z-index: 3;
  cursor: pointer;
}
.job-gallery__cover:hover .hover {
  opacity: 1;
}
.job-gallery__cover img {
  display: block;
}
.job-gallery__cover .front .job-gallery__title {
  color: #666;
}
.job-gallery__cover .hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.8s ease;
}
.job-slider {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.job-slider__item .img {
  position: relative;
  width: 66.66%;
  margin-left: auto;
  height: 100%;
  background-size: cover;
}
.job-slider__text {
  position: absolute;
  top: 0;
  left: 0;
  width: 33.33%;
  height: 100%;
  padding: 5px 3%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 16px;
  color: #333;
  line-height: 1.4;
  font-weight: 300;
}
.job-slider__buttons {
  position: absolute;
  z-index: 50;
  right: 17px;
  width: 25px;
  height: 20px;
  cursor: pointer;
  opacity: 0.7;
}
.job-slider__buttons:hover {
  opacity: 1;
}
.job-slider__buttons.job-button-top {
  top: 17%;
}
.job-slider__buttons.job-button-bottom {
  bottom: 19%;
}
.job-slider__buttons.job-button-bottom img {
  transform: rotate(180deg);
}
.job-slider__buttons img {
  width: 25px;
}
.job-slider .swiper-pagination-bullets {
  right: 25px;
}
.job-slider .swiper-pagination-bullet {
  background: rgba(240,240,240,0.6);
  opacity: 1;
  transition: background 0.3s;
}
.job-slider .swiper-pagination-bullet-active {
  background: #fff;
}
.job-slider__item .job-slider__text {
  background: #bdd3b9;
}
.job-slider__item:nth-child(2n) .job-slider__text {
  background: #b89fba;
}
.job-slider__item:nth-child(3n) .job-slider__text {
  background: #bdd3b9;
}
.job-slider__item:nth-child(4n) .job-slider__text {
  background: #9fb1bf;
}
.job-slider__item:nth-child(5n) .job-slider__text {
  background: #e2c4ab;
}
.job-slider__text p {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.8s, transform 0.8s;
}
.job-slider__text p.first {
  transition-delay: 0.8s;
}
.job-slider__text p.second {
  transition-delay: 1.6s;
}
.swiper-slide-active .job-slider__text p {
  opacity: 1;
  transform: none;
}
@media (max-width: 1300px) {
  .job-slider__text {
    font-size: 14px;
  }
}
@media (max-width: 1100px) {
  .job-gallery__cover {
    height: 250px;
  }
  .job-gallery__cover .front {
    display: none;
  }
  .job-gallery__cover .hover {
    opacity: 1;
  }
  .job-gallery__cover >div {
    height: 100%;
  }
  .job-gallery__cover img {
    height: 100%;
  }
  .job-slider__item {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
  }
  .job-slider__text {
    position: static;
    width: 100%;
    padding: 5px 12px;
  }
  .job-slider .img {
    width: 100%;
    flex-grow: 1;
  }
  .job-slider__pagination.swiper-pagination-bullets {
    top: 43%;
    left: auto;
    right: auto;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .job-slider__pagination.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 5px;
  }
  .job-slider__buttons.job-button-top {
    top: 5%;
  }
  .job-slider__buttons.job-button-bottom {
    top: 33%;
  }
}
@media (max-width: 600px) {
  .job-gallery {
    margin-left: -12px;
    margin-right: -12px;
  }
  .job-slider__text {
    font-size: 12px;
  }
}
.job-btn {
  cursor: pointer;
  display: block;
  width: 436px;
  height: 71px;
  margin: 0 auto 30px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 300;
  color: #333;
  line-height: 1;
  text-decoration: none;
  background: none;
  outline: none;
  transition: 0.3s;
  user-select: none;
}
.job-btn:hover {
  background: #333;
  color: #fff;
  border-color: #333;
}
.job-btn .icons {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 2.2em;
}
.job-btn .icon-arr-r,
.job-btn .icon-arr-l {
  font-size: 1.3em;
}
.job-btn .reverse {
  display: none;
}
.job-btn .icon-arr-r {
  display: none;
}
.job-btn:hover .no-h {
  display: none;
}
.job-btn:hover .icon-arr-r,
.job-btn:hover .icon-arr-l {
  display: block;
}
.job-btn.active .to {
  display: none;
}
.job-btn.active .reverse {
  display: flex;
}
.job-list {
  position: relative;
  margin: 0 -4px;
  font-size: 16px;
}
.job-list * {
  box-sizing: border-box;
}
.job-list:after {
  display: table;
  content: '';
  clear: both;
}
.job-list__item {
  float: left;
  width: 33.333333%;
  height: 264px;
  margin-bottom: 8px;
  text-align: center;
}
.job-list__item.disabled {
  pointer-events: none;
}
.job-list__item.disabled .wrap {
  color: rgba(102,102,102,0.38);
}
.job-list__item.no-vakancy .wrap {
  background: #fff;
}
.job-list__item.no-vakancy .wrap:hover {
  background: #f8e507 !important;
  color: #333 !important;
}
.job-list__item.no-vakancy .wrap:before {
  content: '';
  position: absolute;
  top: 3%;
  left: 3%;
  width: 94%;
  height: 94%;
  border: 1px solid #333;
}
.job-list__item .wrap {
  position: relative;
  display: flex;
  background: #f0f0f0;
  text-decoration: none;
  color: inherit;
  height: 264px;
  margin: 0 4px 8px;
  padding: 10px 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: background 0.5s, color 0.3s;
}
.job-list__item .top {
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 66px 66px 0;
  border-color: transparent #b40b0b transparent transparent;
  transition: opacity 0.3s;
}
.job-list__item .top span {
  position: absolute;
  top: 15px;
  right: -57px;
  transform: rotate(45deg);
}
.job-list__title {
  text-transform: uppercase;
}
.job-list__place {
  font-size: 14px;
  margin-top: 24px;
}
.job-list__item:nth-child(1n) .wrap:hover {
  color: #fff;
  background: #8faa8a;
}
.job-list__item:nth-child(1n) .wrap:hover .top {
  opacity: 0;
}
.job-list__item:nth-child(2n) .wrap:hover {
  background: #9fb1bf;
}
.job-list__item:nth-child(3n) .wrap:hover {
  background: #cfa684;
}
.job-list__item:nth-child(4n) .wrap:hover {
  background: #c2867a;
}
.job-list__item:nth-child(6) .wrap:hover {
  background: #8faa8a;
}
.job-list__item:nth-child(8n) .wrap:hover {
  background: #967499;
}
.job-breadcrumbs {
  margin: 45px 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
}
.job-breadcrumbs li {
  list-style: none;
  font-size: 20px;
  margin: 0 10px;
  font-weight: 300;
}
.job-breadcrumbs li.current {
  font-weight: bold;
}
.job-breadcrumbs li.prev a,
.job-breadcrumbs li.next a {
  width: 25px;
  height: 14px;
  display: block;
  text-decoration: none;
  opacity: 0.7;
  transition: opacity 0.3s;
}
.job-breadcrumbs li.prev a:hover,
.job-breadcrumbs li.next a:hover {
  opacity: 1;
}
.job-breadcrumbs li.prev a {
  margin-right: 40px;
  background: url("../img/svg/icons/arr-l.svg") center center no-repeat;
}
.job-breadcrumbs li.next a {
  margin-left: 40px;
  background: url("../img/svg/icons/arr-r.svg") center center no-repeat;
}
.job-breadcrumbs a {
  color: inherit;
  text-decoration: none;
}
.job-breadcrumbs a:hover {
  text-decoration: underline;
}
.job-filter {
  position: absolute;
  top: 0;
  left: 4px;
  width: calc(100% - 8px);
  padding: 29px 34px 52px;
  background: #f0f0f0;
  border-bottom: 1px solid #333;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-10px);
  transition: opacity 0.3s, transform 0.3s;
}
.job-filter.active {
  opacity: 1;
  z-index: 100;
  pointer-events: auto;
  transform: none;
}
.job-filter__wrap {
  display: flex;
  margin-bottom: 49px;
}
.job-filter__item {
  width: 50%;
}
.job-filter__item:first-child {
  padding-right: 25px;
  border-right: 1px solid #231f20;
}
.job-filter__item:last-child {
  padding-left: 25px;
}
.job-filter__title {
  margin-bottom: 39px;
  color: #333;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
}
.job-filter__btn {
  display: block;
  width: 436px;
  height: 71px;
  margin: 0 auto 30px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 300;
  color: #333;
  line-height: 1;
  text-decoration: none;
  background: none;
  outline: none;
  transition: 0.3s;
  user-select: none;
  cursor: pointer;
}
.job-filter__btn:hover {
  background: #333;
  color: #fff;
  border-color: #333;
}
.job-filter__btn .icons {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 2.2em;
}
.job-filter__btn .icon-arr-r {
  font-size: 1.3em;
}
.job-filter__remove {
  text-align: center;
}
.job-filter__remove a {
  position: relative;
  display: inline-block;
  padding-right: 17px;
  margin: 36px auto;
  color: #333;
  text-decoration: none;
  font-weight: 300;
}
.job-filter__remove a:hover {
  text-decoration: underline;
}
.job-filter__remove a:before,
.job-filter__remove a:after {
  content: '';
  position: absolute;
  top: 7px;
  right: 0;
  width: 12px;
  height: 12px;
  height: 1px;
  background: #333;
}
.job-filter__remove a:before {
  transform: rotate(45deg);
}
.job-filter__remove a:after {
  transform: rotate(-45deg);
}
.job-filter__selectors {
  display: flex;
  flex-wrap: wrap;
}
.job-filter__selectors label {
  display: flex;
  align-items: center;
  position: relative;
  width: 45%;
  height: 39px;
  margin-bottom: 10px;
  padding-left: 25px;
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
}
.job-filter__selectors label:hover {
  color: #fff;
  background: #333;
}
.job-filter__selectors label:hover span:before {
  border-color: #fff;
}
.job-filter__selectors label.disabled {
  color: #7a7a7a;
  pointer-events: none;
}
.job-filter__selectors label span:before {
  content: '';
  position: absolute;
  top: 0.8em;
  left: 5px;
  width: 9px;
  height: 4px;
  border-left: 2px solid #333;
  border-bottom: 2px solid #333;
  transform: rotate(-45deg);
  opacity: 0;
}
.job-filter__selectors label input {
  position: absolute;
  display: none;
}
.job-filter__selectors label input:checked + span:before {
  opacity: 1;
}
@media (max-width: 1000px) {
  .job-container {
    padding: 0 15px;
  }
}
@media (max-width: 770px) {
  .job-list__item .wrap {
    padding: 10px;
  }
  .job-gallery__title {
    font-size: 24px;
  }
  .job-filter {
    padding: 25px;
  }
  .job-filter__wrap {
    flex-direction: column;
  }
  .job-filter__item {
    width: 100%;
  }
  .job-filter__item:first-child {
    margin-bottom: 30px;
    padding: 0 0 10px 0;
    border: 0;
    border-bottom: 1px solid #333;
  }
  .job-filter__item:last-child {
    padding-left: 0;
  }
  .job-filter__title {
    margin-bottom: 20px;
  }
}
@media (max-width: 600px) {
  .job-container {
    padding: 0 12px;
  }
  .job-list {
    margin: 0 -12px;
  }
  .job-list__item {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .job-filter {
    padding: 25px 15px;
  }
  .job-filter__selectors {
    flex-direction: column;
  }
  .job-filter__selectors label {
    width: 100%;
  }
  .job-filter__selectors label:hover {
    color: #333;
    background: #f0f0f0;
  }
  .job-filter__selectors label:hover span:before {
    border-color: #333;
  }
}
@media (max-width: 500px) {
  .job-btn,
  .job-filter__btn {
    height: 46px;
    max-width: 100%;
    font-size: 15px;
  }
  .job-gallery__title {
    font-size: 18px;
  }
}
