.job-btn
	cursor pointer
	btn()
	.icon-arr-r, .icon-arr-l
		font-size 1.3em
	.reverse
		display none
	.icon-arr-r
		display none
	&:hover
		.no-h
			display none
		.icon-arr-r,
		.icon-arr-l
			display block
	&.active
		.to 
			display none
		.reverse
			display flex			

.job-list 
	position relative
	margin 0 -4px
	font-size 16px
	* 
		box-sizing border-box
	&:after
		display table
		content ''
		clear both
	&__item
		float left
		width 33.333333%
		height  264px
		margin-bottom 8px
		text-align center
		&.disabled
			pointer-events none
			.wrap
				color rgba(102, 102, 102, 0.38);
		&.no-vakancy
			.wrap
				background #fff
				&:hover
					background #F8E507 !important
					color #333 !important
				&:before
					content ''
					position absolute
					top 3%
					left 3%
					width 94%
					height 94%
					border 1px solid #333
		.wrap
			position relative
			display flex
			background #F0F0F0
			text-decoration none
			color inherit
			height 264px
			margin 0 4px 8px
			padding 10px 2em
			display flex
			align-items center
			justify-content center
			flex-direction column
			transition background .5s, color .3s
		.top
			position absolute
			top 0
			right 0
			color #fff
			font-size 14px
			text-transform uppercase
			font-weight bold
			width 0
			height 0
			border-style solid
			border-width 0 66px 66px 0
			border-color transparent #b40b0b transparent transparent
			transition opacity .3s
			span
				position absolute
				top 15px
				right -57px
				transform rotate(45deg)
	&__title
		text-transform uppercase
	&__place
		font-size 14px
		margin-top 24px 
		
.job-list__item:nth-child(1n) .wrap:hover
	color #fff
	background #8FAA8A
	.top
		opacity 0
.job-list__item:nth-child(2n) .wrap:hover
	background #9FB1BF
.job-list__item:nth-child(3n) .wrap:hover
	background #CFA684
.job-list__item:nth-child(4n) .wrap:hover
	background #C2867A
.job-list__item:nth-child(6)  .wrap:hover
	background	#8FAA8A
.job-list__item:nth-child(8n) .wrap:hover
	background #967499
	
		
.job-breadcrumbs
	margin 45px 0
	padding 0
	display flex
	align-items center
	justify-content center
	color #333333
	li
		list-style none
		font-size 20px
		margin 0 10px
		font-weight 300
		&.current
			font-weight bold
		&.prev a, &.next a 
			width 25px
			height 14px
			display block
			text-decoration none
			opacity .7
			transition opacity .3s
			&:hover
				opacity 1
		&.prev a
			margin-right 40px
			background url(../img/svg/icons/arr-l.svg) center center no-repeat;
		&.next a
			margin-left 40px	
			background url(../img/svg/icons/arr-r.svg) center center no-repeat;
				
	a
		color inherit
		text-decoration none
		&:hover
			text-decoration underline
	

.job-filter
	position absolute
	top 0
	left 4px
	width calc(100% - 8px)
	padding 29px 34px 52px
	background #F0F0F0
	border-bottom 1px solid #333
	opacity 0
	pointer-events none
	transform translateY(-10px)
	transition opacity .3s, transform .3s
	&.active
		opacity 1
		z-index 100
		pointer-events auto
		transform none
	&__wrap
		display flex
		margin-bottom 49px
	&__item
		width 50%
		&:first-child
			padding-right 25px
			border-right 1px solid #231F20;
		&:last-child
			padding-left 25px
	&__title
		margin-bottom 39px
		color #333333
		font-weight bold
		font-size 20px
		text-transform uppercase
	&__btn
		btn()
		cursor pointer
		.icon-arr-r
			font-size 1.3em
	&__remove
		text-align center
		a 
			position relative
			display inline-block
			padding-right 17px
			margin 36px auto
			color #333
			text-decoration none
			font-weight 300
			&:hover
				text-decoration underline
			&:before, &:after
				content ''
				position absolute
				top 7px
				right 0
				width 12px
				height 12px
				height 1px
				background #333
			&:before 
				transform rotate(45deg)
			&:after 
				transform rotate(-45deg)
	&__selectors
		display flex
		flex-wrap wrap
		label
			display flex
			align-items center
			position relative
			width 45%
			height 39px
			margin-bottom 10px
			padding-left 25px
			font-size 16px
			font-weight 300
			text-transform uppercase
			cursor pointer
			transition background .3s, color .3s
			&:hover
				color #fff
				background #333333
				span:before
					border-color #fff
			&.disabled
				color #7A7A7A
				pointer-events none	
			span
				&:before
					content ''
					position absolute
					top .8em
					left 5px
					width 9px
					height 4px
					border-left 2px solid #333
					border-bottom 2px solid #333
					transform rotate(-45deg)
					opacity 0
			input
				position absolute
				display none
				&:checked + span:before
					opacity 1

// media
+mw(1000px)
	.job-container
		padding 0 15px
		
+mw(770px)
	.job-list__item .wrap
		padding 10px
	.job-gallery__title
		font-size 24px	
	.job-filter
		padding 25px
		&__wrap
			flex-direction column	
		&__item
			width 100%
			&:first-child
				margin-bottom 30px
				padding 0 0 10px 0
				border 0
				border-bottom 1px solid #333
			&:last-child
				padding-left 0
		&__title
			margin-bottom 20px
			

+mw(600px)
	.job-container
		padding 0 12px
	.job-list
		margin 0 -12px
	.job-list__item
		width 100%
		margin-left 0
		margin-right 0
	.job-filter
		padding 25px 15px
		&__selectors
			flex-direction column
			label 
				width 100%
				&:hover
					color #333
					background #f0f0f0
					span:before
						border-color #333
		
+mw(500px)
	.job-btn, .job-filter__btn
		height 46px
		max-width 100%
		font-size 15px
	.job-gallery__title
		font-size 18px