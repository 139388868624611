.job-gallery
	position relative
	margin-bottom 8px
	background #b89fba
	img
		width 100%
	&__title
		font-size 36px
		color #ffff
		font-weight bold
		text-transform uppercase
		position absolute
		top 50%
		left 50%
		transform translate(-50%, -50%)
	&__cover
		position relative
		z-index 3
		cursor pointer
		&:hover
			.hover
				opacity 1
		img
			display block
			
		.front .job-gallery__title
			color #666666	
		.hover
			position absolute
			top 0
			left 0
			width 100%
			height 100%
			opacity 0
			transition opacity .8s ease
			


.job-slider
	position absolute
	z-index 2
	top 0
	left 0
	overflow hidden
	top 0
	left 0
	width 100%
	height 100%
	opacity 0
	&__item
		.img
			position relative
			width 66.66%
			margin-left auto
			height 100%
			background-size cover
	&__text
		position absolute
		top 0
		left 0
		width 33.33%
		height 100%
		padding 5px 3%
		display flex
		align-items center
		justify-content center
		flex-direction column
		text-align center
		font-size 16px
		color #333333
		line-height 1.4
		font-weight 300
	&__buttons
		position absolute
		z-index 50
		right 17px
		width 25px
		height 20px
		cursor pointer
		opacity 0.7
		&:hover
			opacity 1
		&.job-button-top
			top 17%
		&.job-button-bottom
			bottom 19%
			img
				transform rotate(180deg)
		img
			width 25px

	.swiper-pagination-bullets
		right 25px
	.swiper-pagination-bullet
		background rgba(240, 240, 240, 0.6);
		opacity 1
		transition background .3s
	.swiper-pagination-bullet-active
		background #fff	
		



.job-slider__item .job-slider__text
	background #BDD3B9
.job-slider__item:nth-child(2n) .job-slider__text
	background #B89FBA
.job-slider__item:nth-child(3n) .job-slider__text
	background #BDD3B9
.job-slider__item:nth-child(4n) .job-slider__text
	background #9FB1BF
.job-slider__item:nth-child(5n) .job-slider__text
	background #E2C4AB
	

// animate
.job-slider__text	p 
	opacity 0
	transform translateY(-10px)
	transition opacity .8s, transform .8s
	&.first
		transition-delay .8s	
	&.second
		transition-delay 1.6s	
		
.swiper-slide-active .job-slider__text	p 
	opacity 1
	transform none
	

// media
+mw(1300px)
	.job-slider__text
		font-size 14px
		
+mw(1100px)
	.job-gallery
		&__cover
			height 250px
			.front
				display none
			.hover
				opacity 1	
			>div
				height 100%
			img
				height 100%
	.job-slider
		&__item
			display flex
			flex-direction column-reverse
			height 100%
		&__text
			position static
			width 100%
			padding 5px 12px
		.img
			width 100%
			flex-grow 1
		&__pagination.swiper-pagination-bullets
			top 43%
			left auto
			right  auto
			width 100%
			display flex
			justify-content center
			.swiper-pagination-bullet
				margin 0 5px
		&__buttons
			&.job-button-top
				top 5%
		&__buttons
			&.job-button-bottom
				top 33%			
			
+mw(600px)
	.job-gallery
		margin-left -12px
		margin-right -12px
	.job-slider__text
		font-size 12px