@import 'utils/fonts.styl'
// @import 'utils/vars.styl'
@import 'utils/mixins.styl'

body 
	font-family  'GothamPro', sans-serif

.job-container 
	position relative
	padding 0 60px



//Модули
@import "include/include.styl"

// СТРАНИЦЫ
// Главная страница
@import 'pages/index.styl'