.job-header
	margin-bottom 74px
	.close_btn
		top 20px
		right 25px
		&:before, &:after
			margin -1px 0 0 -16px
	&__title
		position absolute
		bottom 0
		left 50%
		margin 0
		transform translateX(-50%)
		font-size 40px
		font-weight 300
		line-height .9
	.logo
		display block
		width 184px
		height 81px
		margin-top 71px
		

// media
+mw(1000px)
	.job-header .close_btn
		top 0
		right 0

+mw(770px)
	.job-header
		margin-bottom 30px
		.job-container
			display flex
			align-items center
			justify-content center
			flex-direction column
		.logo
			width 125px
			height 55px
			margin 34px 0 16px
	.job-header__title
		position static
		transform none
